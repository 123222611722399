import React, { useState, useEffect, useCallback } from "react";
import {
    Card,
    CardHeader,
    Table,
    Button,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import EeiServices, { PRODUCT_TYPE } from "../../service/eei";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import Swal from 'sweetalert2';
import CurrencyInput from "react-currency-input-field";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-custom-build"
import DataTable from "react-data-table-component";
import { tableCustomStyle } from "utils";
import countries from "../../country.json"

import { convertHtmlDate } from "utils";
import { createEvent, EditEvent } from "service/event.api";

const Event = (props) => {
    const [modal, setModal] = useState(false);

    const [eventCategories, setEventCategories] = useState([])

    const [event, setEvent] = useState({
        files: File | null,
        name: "",
        title: "",
        description: "",
        price: 0,
        salePrice: 0,
        measurement: 0,
        discount: 0,
        stockOnHand: 0,
        sold: 0,
        permalink: "",
        isRecommended: false,
        isFeatured: false,
        typeId: PRODUCT_TYPE.EVENT,
        eventCategoriesId: 0,
        end: new Date(),
        start: new Date(),
        country: ""
    })

    const [events, setEvents] = useState([]);
    const [eventMeta, setEventMeta] = useState({
        totalItems: 0
    });

    const handleFileChangeNew = (e) => {
        event.files = e.target.files[0];
        setSelectedFile(event.files)
    }

    const toggleModal = () => setModal(!modal);
    const [editModal, setEditModal] = useState(false);
    const toggleEditModal = () => setEditModal(!editModal);

    const [editEventData, setEditEventData] = useState({
        id: null,
        files: null,
        name: "",
        title: "",
        description: "",
        price: "",
        salePrice: "",
        measurement: "",
        discount: "",
        stockOnHand: "",
        permalink: "",
        recommended: false,
        isFeatured: false,
        typeId: "",
        eventCategoriesId: "",
        end: new Date(),
        country: ""
    });

    const [selectedData, setSelectedData] = useState(0)
    const [selectedFile, setSelectedFile] = useState();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    let previewEditRef = React.useRef();
    let imgRef = React.useRef();

    const onEditRefChange = useCallback(node => {
        if (node && selectedFile) {
            previewEditRef = node;
            previewEditRef.style.background = 'url("' + URL.createObjectURL(selectedFile) + '")';
        }
        else if (node && editEventData.files) {
            previewEditRef = node;
            previewEditRef.style.background = 'url("' + editEventData.files + '")';
        }

    }, [selectedFile, editEventData.files]);


    const removeImg = useCallback(() => {
        setSelectedFile(null);
        setEditEventData({ ...editEventData, files: null });

        if (previewEditRef && previewEditRef.style) {
            previewEditRef.style.background = 'transparent'
        }

        if (imgRef) {
            imgRef.value = '';
        }
    }, [selectedFile, editEventData.files]);

    const openEditModal = async (item) => {
        setEditEventData({
            id: item.id,
            name: item.name,
            title: item.title,
            description: item.description,
            price: item.price,
            salePrice: item.salePrice,
            measurement: item.measurement,
            discount: item.discount,
            stockOnHand: item.stockOnHand,
            permalink: item.permalink,
            recommended: item.isRecommended,
            isFeatured: item.isFeatured,
            eventCategoriesId: item.eventCategories?.id,
            sold: item.sold,
            end: new Date(item.end),
            start: new Date(item.start),
            country: item.country
        });
        try {
            const res = await fetch(item.eventImages);
            const blob = await res.blob();
            setEditEventData(prevState => ({ ...prevState, files: blob }));
            setSelectedFile(blob);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed init edit image'
            })
        }
        toggleEditModal();
    };

    const handleDeleteClick = (id) => {
        Swal.fire({
            title: "Loading",
            async didOpen() {
                try {
                    Swal.showLoading();
                    const eeiServices = new EeiServices();;
                    await eeiServices.deleteEvent(id);
                    fetchData();
                    Swal.close()
                } catch (err) {
                    console.log(err)
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to Delete',
                    });
                }
            }
        });
    };

    const handleEditSubmit = async () => {
        try {

            Swal.fire({
                title: "Loading"
            });
            Swal.showLoading();
            await EditEvent(events[selectedData].id, editEventData, events[selectedData]);
            Swal.close();

            fetchData();
            toggleEditModal();
        } catch (error) {
            console.error('Failed to submit edited banner:', error);
            let msg = error.message;

            if(Array.isArray(error.response?.data?.message)) {
                msg = "";
                error.response.data.message.forEach(v => {
                    msg += `<p>${v}</p>`
                })
            }

            Swal.fire({
                icon: 'error',
                title: 'Failed to Save Changes',
                text: msg,
            });
        }
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getEvent(page, limit);
            const eventCategory = await eeiServices.getActiveEventCategory();

            setEvents(result.data);
            setEventMeta(result.meta);
            setEventCategories(eventCategory.data);
        } catch (error) {
            console.log(error)
            Swal.fire({
                title: "Error",
                text: "Error when get data"
            }, null, "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, limit]);

    const handleSubmit = async () => {
        if (event.country == "0") {
            alert('please choose the country');
            return;
        }
        try {
            Swal.fire({
                title: "Loading",
            });
            Swal.showLoading();
            await createEvent(event);
            Swal.close();

            Swal.fire({
                icon: 'success',
                title: 'Event Posted!',
                text: 'The event has been posted successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    toggleModal();
                }
            });

            fetchData();
        } catch (error) {
            console.error('Failed to post event:', error);

            let msg = error.message;

            if (Array.isArray(error.response?.data?.message)) {
                msg = "";
                error.response.data.message.forEach(v => {
                    msg += `<p>${v}</p>`
                })
            }

            Swal.fire({
                icon: 'error',
                title: 'Failed to Post event',
                html: msg
            });
        }
    };


    useEffect(() => {
        let price = editEventData.price,
            discount = editEventData.discount;
        const sale_price = (price - discount) < 0 ? 0 : (price - discount);
        setEditEventData({
            ...editEventData,
            salePrice: sale_price
        });

    }, [editEventData.price, editEventData.discount]);

    useEffect(() => {
        let price = event.price,
            discount = event.discount;
        const sale_price = (price - discount) < 0 ? 0 : (price - discount);
        setEvent({
            ...event,
            salePrice: sale_price
        });

    }, [event.price, event.discount]);

    const columns = [
        {
            name: "File Image",
            selector: row => row.image,
            cell: row => {
                return <img style={{ width: "90px", height: "70px" }} src={row.eventImages} alt="Course" />
            }
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Category',
            selector: row => row.eventCategories.name,
        },
        {
            name: 'Actions',
            selector: row => row.id,
            cell: (row, id) => (
                <>
                    <Button color="info" size="sm" className="mr-2" onClick={() => {
                        openEditModal(row)
                        setSelectedData(id);
                    }}>
                        <FaEdit />
                    </Button>
                    <Button color="danger" size="sm" onClick={() => handleDeleteClick(row.id)}>
                        <FaTrash />
                    </Button>
                </>
            )
        }
    ];

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container className="mt-2" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3 className="text-white mb-0">Event Active</h3>
                                    <Button color="primary" onClick={toggleModal}>
                                        <FaPlus className="mr-2" />
                                        Add
                                    </Button>
                                </CardHeader>
                                <DataTable
                                    customStyles={tableCustomStyle}
                                    columns={columns}
                                    data={events}
                                    paginationTotalRows={eventMeta.totalItems}
                                    pagination
                                    onChangeRowsPerPage={rows => setLimit(rows)}
                                    paginationServer
                                    onChangePage={page => setPage(page)}
                                    progressPending={loading}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal} toggle={toggleModal} size="xl">
                <ModalHeader toggle={toggleModal}>Add Event</ModalHeader>

                <ModalBody>
                    <FormGroup>
                        <Label for="categoryIdInput">Category Event</Label>
                        <Input
                            type="select"
                            id="categoryIdInput"
                            value={event.eventCategoriesId}
                            defaultValue={""}
                            onChange={event1 => {
                                setEvent({ ...event, eventCategoriesId: Number(event1.target.value) })
                            }}
                        >
                            <option value={""}>-- Pilih Event Category --</option>
                            {eventCategories.map(value => {
                                return (<option value={value.id}>{value.name}</option>)
                            })}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for="fileInput">Choose File</Label>
                        <Input
                            type="file"
                            id="fileInput"
                            onChange={event1 => {
                                setEvent({ ...event, files: event1.target.files[0] })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="nameInput">Name</Label>
                        <Input
                            type="text"
                            id="nameInput"
                            value={event.name}
                            onChange={event1 => {
                                setEvent({ ...event, name: event1.target.value })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="titleInput">Title</Label>
                        <Input
                            type="text"
                            id="titleInput"
                            value={event.title}
                            onChange={event1 => {
                                setEvent({ ...event, title: event1.target.value })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editor">Description</Label>
                        <CKEditor
                            config={{
                                mediaEmbed: {
                                    previewsInData: true,
                                },
                            }}
                            editor={ClassicEditor}
                            data={event.description || ''}
                            onChange={(_, data) => {
                                setEvent({ ...event, description: data.getData() })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="PriceInput">Price</Label>
                        <CurrencyInput intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                            value={event.price}
                            onValueChange={value => {
                                setEvent({ ...event, price: Number(value) })
                            }}
                            className={"form-control"} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="salePriceInput">Sale Price</Label>
                        <CurrencyInput intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                            value={event.salePrice}
                            onValueChange={value => {
                                setEvent({ ...event, salePrice: Number(value) })
                            }}
                            className={"form-control"} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="measurementInput">Measurement</Label>

                        <Input
                            type="number"
                            id="measurementInput"
                            value={event.measurement}
                            onChange={event1 => {
                                setEvent({ ...event, measurement: Number(event1.target.value) })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="discountInput">Discount</Label>

                        <CurrencyInput suffix={"%"}
                            value={event.discount}
                            onValueChange={value => {
                                setEvent({ ...event, discount: Number(value) })
                            }}
                            className={"form-control"}
                            maxLength={3} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="stockOnHandInput">Stock On Hand</Label>
                        <Input
                            type="number"
                            id="stockOnHandInput"
                            value={event.stockOnHand}
                            onChange={event1 => {
                                setEvent({ ...event, stockOnHand: Number(event1.target.value) })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="stockOnHandInput">Sold</Label>
                        <Input
                            type="number"
                            id="sold"
                            value={event.sold}
                            onChange={event1 => {
                                setEvent({ ...event, sold: Number(event1.target.value) })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="permalinkInput">Permalink</Label>
                        <Input
                            type="text"
                            id="permalinkInput"
                            value={event.permalink}
                            onChange={event1 => {
                                setEvent({ ...event, permalink: event1.target.value })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="start">Start</Label>
                        <Input
                            type="date"
                            id="start"
                            value={convertHtmlDate(event.start)}
                            required
                            onChange={event1 => {
                                setEvent({ ...event, start: new Date(event1.target.value).toISOString() })
                            }}
                        />
                    </FormGroup>


                    <FormGroup>
                        <Label for="end">End</Label>
                        <Input
                            type="date"
                            id="end"
                            value={convertHtmlDate(event.end)}
                            required
                            onChange={event1 => {
                                setEvent({ ...event, end: new Date(event1.target.value).toISOString() })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="countryCreate">Country</Label>
                        <Input
                            type="select"
                            id="countryCreate"
                            defaultValue={event.country}
                            required
                            onChange={event1 => {
                                setEvent({ ...event, country: event1.target.value })
                            }}
                        >
                            <option value={0}>Select Country</option>
                            {countries.map((country, index) =>
                                <option key={index} value={country.name}>{country.name}</option>
                            )}
                        </Input>
                    </FormGroup>

                    <FormGroup className="ml-3" >
                        <Input
                            type="checkbox"
                            id="recommendedInput"
                            checked={event.isRecommended}
                            onChange={event1 => {
                                setEvent({ ...event, isRecommended: Boolean(event1.target.value) })
                            }}
                        />
                        <Label for="recommendedInput">Is Recommended</Label>
                    </FormGroup>

                    <FormGroup className="ml-3" >
                        <Input
                            type="checkbox"
                            id="featuredInput"
                            checked={event.isFeatured}
                            onChange={event1 => {
                                setEvent({ ...event, isFeatured: Boolean(event1.target.value) })
                            }}
                        />
                        <Label for="featuredInput">Is Featured</Label>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                        Post Event
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={editModal} toggle={toggleEditModal} size="xl">
                <ModalHeader toggle={toggleEditModal}>Edit Event</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="editcategoryIdInput">Category Event</Label>
                        <Input
                            type="select"
                            id="editcategoryIdInput"
                            value={editEventData.eventCategoriesId}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, eventCategoriesId: e.target.value })
                            }
                        >
                            <option value={""}>-- Pilih Event Category --</option>
                            {eventCategories.map(value => {
                                return (<option key={value.id} value={value.id}>{value.name}</option>)
                            })}
                        </Input>
                    </FormGroup>
                    <div className="row">
                        <FormGroup className="col-md-6" >
                            <Label for="fileInput">Choose File</Label>
                            <Input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                ref={el => imgRef = el}
                                onChange={handleFileChangeNew}
                            />
                        </FormGroup>
                        <div className="col-md-6 d-flex align-items-center justify-content-center" >
                            <div className="position-relative preview-img" ref={onEditRefChange} style={{ width: '250px', height: '150px' }} >
                                {selectedFile || editEventData.files ?
                                    <button type="button" className="close position-absolute button-remove-img" onClick={removeImg} >
                                        <span aria-hidden="true" className="text-white" >×</span>
                                    </button>
                                    : ''}
                            </div>
                        </div>
                    </div>


                    <FormGroup>
                        <Label for="editNameInput">Name</Label>
                        <Input
                            type="text"
                            id="editNameInput"
                            value={editEventData.name}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, name: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editTitleInput">Title</Label>
                        <Input
                            type="text"
                            id="editTitleInput"
                            value={editEventData.title}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, title: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editDescriptionInput">Description</Label>
                        <CKEditor
                            config={{
                                mediaEmbed: {
                                    previewsInData: true,
                                },
                            }}
                            editor={ClassicEditor}
                            data={editEventData.description || ''}
                            onChange={(_, data) => {
                                setEditEventData({ ...editEventData, description: data.getData() })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editPriceInput">Price</Label>
                        <Input
                            type="number"
                            id="editPriceInput"
                            value={editEventData.price}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, price: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editsalePriceInput">Sale Price</Label>
                        <Input
                            type="number"
                            id="editsalePriceInput"
                            value={editEventData.salePrice}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, salePrice: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editmeasurementInput">Measurement</Label>
                        <Input
                            type="number"
                            id="editmeasurementInput"
                            value={editEventData.measurement}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, measurement: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editdiscountInput">Discount</Label>
                        <Input
                            type="number"
                            id="editdiscountInput"
                            value={editEventData.discount}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, discount: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editstockOnHandInput">StockOnHand</Label>
                        <Input
                            type="number"
                            id="editstockOnHandInput"
                            value={editEventData.stockOnHand}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, stockOnHand: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editsoldInput">Sold</Label>
                        <Input
                            type="number"
                            id="editsoldInput"
                            value={editEventData.sold}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, sold: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="editpermalinkInput">Permalink</Label>
                        <Input
                            type="text"
                            id="editpermalinkInput"
                            value={editEventData.permalink}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, permalink: e.target.value })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="startEdit">End</Label>
                        <Input
                            type="date"
                            id="startEdit"
                            value={convertHtmlDate(editEventData.start)}
                            required
                            onChange={event1 => {
                                setEditEventData({ ...editEventData, start: new Date(event1.target.value).toISOString() })
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="endEdit">End</Label>
                        <Input
                            type="date"
                            id="endEdit"
                            value={convertHtmlDate(editEventData.end)}
                            required
                            onChange={event1 => {
                                setEditEventData({ ...editEventData, end: new Date(event1.target.value).toISOString() })
                            }}
                        />
                    </FormGroup>


                    <FormGroup>
                        <Label for="countryEdit">Country</Label>
                        <Input
                            type="select"
                            id="countryEdit"
                            defaultValue={editEventData.country}
                            required
                            onChange={event1 => {
                                setEditEventData({ ...editEventData, country: event1.target.value })
                            }}
                        >
                            <option value={0}>Select Country</option>
                            {countries.map((country, index) =>
                                <option key={index} value={country.name}>{country.name}</option>
                            )}
                        </Input>
                    </FormGroup>


                    <FormGroup className="ml-3" >
                        <Input
                            type="checkbox"
                            id="editrecommendedInput"
                            checked={editEventData.recommended}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, recommended: e.target.checked })
                            }
                        />
                        <Label for="editrecommendedInput">Is Recommended</Label>
                    </FormGroup>

                    <FormGroup className="ml-3" >
                        <Input
                            type="checkbox"
                            id="editisFeaturedInput"
                            checked={editEventData.isFeatured}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, isFeatured: e.target.checked })
                            }
                        />
                        <Label for="editisFeaturedInput">Is Featured</Label>
                    </FormGroup>

                    <FormGroup>
                        <Input
                            type="hidden"
                            id="edittypeIdInput"
                            value={editEventData.typeId}
                            onChange={(e) =>
                                setEditEventData({ ...editEventData, typeId: e.target.value })
                            }
                        />
                    </FormGroup>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleEditSubmit}>
                        Save Changes
                    </Button>
                    <Button color="secondary" onClick={toggleEditModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Event;
